@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';

.flex-modal {
  max-width: 550px !important;
  overflow: auto;

  p {
    text-align: left;
  }

  button {
    overflow: hidden;
    text-overflow: ellipsis;
    &::-webkit-scrollbar {
      width: 0px;
      background: transparent; /* make scrollbar transparent */
    }
  }

  form {
    max-width: 100%;
    input {
      //height: 56px;
      background-color: #1E2428 !important;
      border: none !important;
      box-shadow: none !important;

      color: #fff !important;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        -webkit-text-fill-color: #fff !important;
        //-webkit-box-shadow: 0 0 0px 1000px var(--page-bg) inset;
        //transition: background-color 5000s ease-in-out 0s;
      }
    }
  }
}

.success {
  background-color: #26DA72;
}

.progress {
  background-color: #4259CF;
}

.circle-lg {
  width:64px;
  height: 64px;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-sm {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-action {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4259CF;
  border-color: #4259CF;
}

.circle-success {
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  &:before, &:after {
    position: absolute;
    content: "";
    display: block;
    border-radius: 50%;
    background: none;
    z-index: 1;
  }
  // &:before {
  //   height: 100px;
  //   width: 100px;
  //   transform: translateX(-100px) translateY(70px);
  //   box-shadow: 0px 0px 500px 100px rgba(map-get($ui-kit-colors, cerise), .07);
  //   background-color: rgba(map-get($ui-kit-colors, cerise), .07);
  // }
  // &:after {
  //   height: 300px;
  //   width: 300px;
  //   transform: translateX(100px);
  //   background-color: rgba(map-get($ui-kit-colors, purple-heart), .08);
  //   box-shadow: 0px 0px 500px 100px rgba(map-get($ui-kit-colors, purple-heart), .08);
  // }

  & > * {
    position: relative;
    z-index: 3;
  }

  .description {
    margin-bottom: 2rem;
    margin-top: .25rem;
    opacity: .5;
    white-space: pre-wrap;
  }

  .logo {
    position: absolute;
    top: 1.125rem;
    left: 1.45rem;
    font-weight: 500;

    svg {
      vertical-align: middle;
      -webkit-transform: translateY(-1px);
      transform: translateY(-1px);
      margin-left: 5px;
      margin-right: 12px;
    }
  }
  .input {
    min-width: 300px;
    z-index: 100;

    & > *, & input {
      height: 3.125rem;
      line-height: 3.125rem !important;
      background-color: var(--page-bg) !important; //#171B1F !important;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        -webkit-text-fill-color: #fff;
        //-webkit-box-shadow: 0 0 0px 1000px var(--page-bg) inset;
        //transition: background-color 5000s ease-in-out 0s;
      }
    }
    input {
      padding-left: 1rem !important;
    }
    .show-button {
      height: 48px;
      padding: 0 .25rem;
      transform: translateY(4px);
    }
  }
  .btn-enter {
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: .25rem;

    svg {
      width: 20px;
      height: auto;
      @include transition(0.2s);
    }

    &:hover {
      svg {
        transform: translateX(-3px);
        @include transition(0.6s);
      }
    }
  }

  .note {
    margin-top: .75rem;
  }
  a {
    color: lighten($main, 3);
  }

  .error {
    animation: shake 0.35s cubic-bezier(.51,.01,.3,1) forwards;
    transform: translate3d(0, 0, 0);
    color: darken($surf-pink, 5%);
  }
  @keyframes shake {
    0%, 90% {
      transform: translate3d(0, 0, 0);
    }

    75% {
      transform: translate3d(2px, 0, 0);
    }

    50% {
      transform: translate3d(-3px, 0, 0);
    }

    25%{
      transform: translate3d(4px, 0, 0);
    }
  }
}
