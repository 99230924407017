@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';


table {
  tbody.trades-loading {
    tr {
      td, th {
        min-width: 40px !important;
      }
    }
  }
  tbody.trades {
    font-variant-numeric: tabular-nums;

    td {
      line-height: 1.375rem !important;
    }
  }
}

.scrollable-table-container {
  height: '100%';
  max-height: 500px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }
  .trade-table-button {
    color: var(--text-color);
    cursor: pointer;
  }

  .trade-table-button:hover {
    color: #0083e0;
    cursor: pointer;
  }

  .trade-table-button.disabled {
    color: #aaa;
    cursor: default;
  }

  table {
    td, th {
      padding-right: 8px;
    }
    td:last-child {
      padding-right: 0px;
    }
    th:last-child {
      padding-right: 0px;
    }
  }
}

.assets-box {
  button {
    width: 100%;
    height: 32px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 8px;
    padding-right: 8px;

    &:before, &:after {
      content: none !important;
    }

    &.btn-assets {
      background-color: #4259CF;
      border-color: #4259CF;
      box-shadow: none !important;

      &:hover {
        &, &:active, &:focus {
          background-color: desaturate(darken(#4259CF, 5), 10);
          border-color: desaturate(darken(#4259CF, 5), 10);
        }
      }

      &:focus {
        &, &:active, &:focus {
          background-color: desaturate(darken(#4259CF, 5), 10);
          border-color: desaturate(darken(#4259CF, 5), 10);
          box-shadow: 0 0 0 0.2rem rgba(#4259CF, 0.3);
        }
      }

      &:disabled, &.disabled {
        &, &:active, &:hover, &:focus {
          background-color: #4259CF;
          border-color: #4259CF;
          box-shadow: none;
          opacity: .5;
          cursor: default;
        }
      }
    }
  }
}

.gas-box {
  button {
    width: 100%;
    height: 32px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 8px;
    padding-right: 8px;

    &:before, &:after {
      content: none !important;
    }

    &.btn-buy {
      background-color: $green;
      border-color: $green;
      box-shadow: none !important;

      &:hover {
        &, &:active, &:focus {
          background-color: desaturate(darken($green, 5), 10);
          border-color: desaturate(darken($green, 5), 10);
        }
      }

      &:focus {
        &, &:active, &:focus {
          background-color: desaturate(darken($green, 5), 10);
          border-color: desaturate(darken($green, 5), 10);
          box-shadow: 0 0 0 0.2rem rgba($green, 0.3);
        }
      }

      &:disabled, &.disabled {
        &, &:active, &:hover, &:focus {
          background-color: $green;
          border-color: $green;
          box-shadow: none;
          opacity: .5;
          cursor: default;
        }
      }
    }
    &.btn-sell {
      background-color: $red;
      border-color: $red;
      box-shadow: none !important;

      &:hover {
        &, &:active, &:focus {
          background-color: desaturate(darken($red, 5), 10);
          border-color: desaturate(darken($red, 5), 10);
        }
      }

      &:focus {
        &, &:active, &:focus {
          background-color: desaturate(darken($red, 5), 10);
          border-color: desaturate(darken($red, 5), 10);
          box-shadow: 0 0 0 0.2rem rgba($red, 0.3);
        }
      }
      &:disabled, &.disabled {
        &, &:active, &:hover, &:focus {
          background-color: $red;
          border-color: $red;
          box-shadow: none;
          opacity: .5;
          cursor: default;
        }
      }
    }
  }
}
